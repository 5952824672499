<template>
    <div class="app">
        <apexchart width="100%" height="200%" type="bar" :options="chartOptions" :series="series"></apexchart>
        <div>
            <!-- <button @click="updateChart">تحديث!</button> -->
        </div>
    </div>
    </template>
    
    <script>
    const numeral = require('numeral');
    export default {
        props: ["dashboard"],
        data: function () {
            return {
                chartOptions: {
                    colors: ['#318194','#4D77A9', '#7A2969', '#D72D74'],
                    chart: {
                        id: 'vuechart-example',
                    },
                    xaxis: {
                        categories: [2021],
                    },
                },
                series: [
                    {
                        name: 'قسم رقم 1',
                        data: [40]
                    },
                    {
                        name: 'قسم رقم 2',
                        data: [30]
                    },
                    {
                        name: 'قسم رقم 3',
                        data: [10]
                    },
                    {
                        name: 'قسم رقم 4',
                        data: [90]
                    },
                    {
                        name: 'قسم رقم 5',
                        data: [60]
                    },
                    {
                        name: 'قسم رقم 6',
                        data: [30]
                    },
                    {
                        name: 'قسم رقم 7',
                        data: [5]
                    },
                    {
                        name: 'قسم رقم 8',
                        data: [20]
                    },
                    {
                        name: 'قسم رقم 9',
                        data: [33]
                    },
                    {
                        name: 'قسم رقم 10',
                        data: [44]
                    },
                    {
                        name: 'قسم رقم 11',
                        data: [48]
                    },
                    {
                        name: 'قسم رقم 12',
                        data: [22]
                    },
                    {
                        name: 'قسم رقم 13',
                        data: [23]
                    },
                    {
                        name: 'قسم رقم 14',
                        data: [9]
                    },
                    {
                        name: 'قسم رقم 15',
                        data: [11]
                    },
                    {
                        name: 'قسم رقم 16',
                        data: [3]
                    },
                    {
                        name: 'قسم رقم 17',
                        data: [21]
                    },
                    {
                        name: 'قسم رقم 18',
                        data: [15]
                    },
                    {
                        name: 'قسم رقم 19',
                        data: [18]
                    },
                ]
            }
            //     series: [],
            //     key:false
            // }
        },
        created() {
            this.key = true
            
            // this.updateChart
            // this.chartOptions = {
            //     colors: ['#318194','#4D77A9', '#7A2969', '#D72D74'],
            //     markers: {
            //         size: 7,
            //     },
            //     xaxis: {
            //         // categories: [ '2021-06', '2021-07', '2021-08', '2021-09', '2021-10', '2021-11', '2021-12'],
            //         categories: this.dashboard.categories
            //     },
            //       yaxis: {
            //             labels: {
            //             /**
            //              * Allows users to apply a custom formatter function to yaxis labels.
            //              *
            //              * @param { String } value - The generated value of the y-axis tick
            //              * @param { index } index of the tick / currently executing iteration in yaxis labels array
            //              */
            //                 formatter: function(val, index) {
            //                     return numeral(val).format("0,0")+' IQD ';
            //                 }
            //             }
            //         },
            // };
            
            // this.series = [
            //     {
            //         name: 'الأقساط',
            //         data: [0,148008]
            //     },
            // ]
            this.chartOptions.xaxis.categories = this.dashboard.categories
            this.series = this.dashboard.series 
        },
        methods: {}
    };
    </script>